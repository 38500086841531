import { useEffect, useState } from 'react';
import queryString from "query-string";
import {DatePicker} from "react-rainbow-components"
import moment from 'moment'

import Base from '../../../utils/base'

export default function ShippingVoucherAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [name, set_name] = useState('')
  const [description, set_description] = useState('')
  const [code, set_code] = useState('')
  const [min_payment, set_min_payment] = useState('0')
  const [max_discount, set_max_discount] = useState('0')
  const [type_discount, set_type_discount] = useState('fixed')
  const [discount, set_discount] = useState('0')
  const [amount, set_amount] = useState('0')
  const [start_date, set_start_date] = useState('')
  const [expired_date, set_expired_date] = useState('')
  const [file_name, set_file_name] = useState('')
  const [base64, set_base64] = useState(null)
  const [query, set_query] = useState({})
  const [first_time, set_first_time] = useState(true)

  useEffect(() => {
    if(!first_time){
      base.limit_data_length(name, set_name)
      base.limit_data_length(code, set_code)
    }
  }, [name, code,])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    if(query1.id != null)
      get_data()
    else
      set_first_time(false)
  }, [])

  useEffect(() => {
    if(!first_time){

      var temp_discount = parseFloat(discount.replace(/\./g,''))

      base.to_currency_format(min_payment, set_min_payment)
      base.to_currency_format(max_discount, set_max_discount)
      base.to_currency_format(discount, set_discount)
      base.to_currency_format(amount, set_amount)
    }
  }, [min_payment, max_discount, discount, amount])

  useEffect(() => {
    if(discount !== ''){
      var temp_discount = parseFloat(discount.replace(/\./g,''))
      if(type_discount === 'percentage' && temp_discount > 100)
        temp_discount = 100
      set_discount(temp_discount.toLocaleString(base.locale_string))
    }
  }, [discount,])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/shipping-voucher?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        if(response.data.file_name != null)
          set_file_name(base.host + '/image/shipping-voucher?file_name=' + response.data.file_name)
        set_min_payment(response.data.min_payment.toLocaleString(base.locale_string))
        set_max_discount(response.data.max_discount.toLocaleString(base.locale_string))
        set_discount(response.data.discount.toLocaleString(base.locale_string))
        set_amount(response.data.amount.toLocaleString(base.locale_string))
        set_code(response.data.code)
        set_first_time(false)
        set_name(response.data.name)
        set_description(response.data.description)
        set_type_discount(response.data.type_discount)
        set_start_date(new Date(response.data.start_at))
        set_expired_date(new Date(response.data.expired_at))
        set_data(response.data)
      }
    })
  }

  function on_upload_image(e){
    var image = URL.createObjectURL(e.target.files[0])
    base.getBase64(e.target.files[0], result => {
      set_base64(result)
    })

    set_file_name(image)
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else if(code === '')
      base.notify_user(base.i18n.t('code_empty'))
    else if(type_discount === '')
      base.notify_user(base.i18n.t('type_discount_empty'))
    else if(discount === '0')
      base.notify_user(base.i18n.t('discount_empty'))
    else if(amount === '0')
      base.notify_user(base.i18n.t('amount_empty'))
    else if(start_date === '')
      base.notify_user(base.i18n.t('start_date_empty'))
    else if(expired_date === '')
      base.notify_user(base.i18n.t('expired_date_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        name: name,
        description: description,
        code: code,
        min_payment: parseFloat(min_payment.replace(/\./g, '')),
        max_discount: parseFloat(max_discount.replace(/\./g, '')),
        type_discount: type_discount,
        discount: parseFloat(discount.replace(/\./g, '')),
        amount: parseFloat(amount.replace(/\./g, '')),
        start_at: moment(start_date).format('YYYY-MM-DD'),
        expired_at: moment(expired_date).format('YYYY-MM-DD'),
      }
      if(base64 != null)
        temp.image = {
          image: base64
        }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/shipping-voucher', queryParams.id == null ? 'post' : 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/shipping-voucher"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_shipping_voucher') : base.i18n.t('add_shipping_voucher')}</h3>
      <div className="row">
        <div className="col-12 col-lg-4 mb-3">
          <div className="card">
            <div className="card-body">
              <input type="file" className="d-none" id="inputFile" onChange={e => on_upload_image(e)}/>
              <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
              <div className="mt-3">
                {
                  file_name != '' ?
                  <img src={file_name} width="100%"/>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mb-3">
          <div className="card">
            <div className="card-body row">
            <div className="form-group col-12">
                <label>{base.i18n.t('code')}</label>
                <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_code(e.target.value)} value={code}/>
              </div>
              <div className="form-group col-12">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_name(e.target.value)} value={name}/>
              </div>
              <div className="form-group col-12">
                <label>{base.i18n.t('description')}</label>
                <textarea className="form-control" onChange={e => set_description(e.target.value)} value={description}></textarea>
              </div>
              <div className="form-group col-12">
                <label>{base.i18n.t('min_payment')}</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">Rp.</div>
                  </div>
                  <input type="text" disabled={query.id != null} className="form-control" onChange={e => set_min_payment(e.target.value)} value={min_payment}/>
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('type_discount')}</label>
                <select className="form-control" disabled={query.id != null} onChange={e => set_type_discount(e.target.value)} value={type_discount}>
                  <option value=''>{base.i18n.t('choose_option')}</option>
                  <option value="fixed">{base.i18n.t('fixed')}</option>
                  <option value="percentage">{base.i18n.t('percentage')}</option>
                </select>
              </div>
              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('discount')}</label>
                {
                  type_discount == 'fixed' ?
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">Rp.</div>
                    </div>
                    <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_discount(e.target.value)} value={discount}/>
                  </div>
                  :
                  <div className="input-group">
                    <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_discount(e.target.value)} value={discount}/>
                    <div className="input-group-append">
                      <div className="input-group-text">%</div>
                    </div>
                  </div>
                }
              </div>
              {
                type_discount === 'percentage' ?
                <div className="form-group col-12">
                  <label>{base.i18n.t('max_discount')}</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">Rp.</div>
                    </div>
                    <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_max_discount(e.target.value)} value={max_discount}/>
                  </div>
                </div>
                :
                <></>
              }
              <div className="form-group col-12">
                <label>{base.i18n.t('amount')}</label>
                <div className="input-group">
                  <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_amount(e.target.value)} value={amount}/>
                  <div className="input-group-append">
                    <div className="input-group-text">Pcs</div>
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('start_date')}</label>
                <DatePicker disabled={query.id != null} locale={base.locale_string} value={start_date} onChange={date => set_start_date(date)} maxDate={new Date(expired_date)}/>
              </div>
              <div className="form-group col-12 col-md-6">
                <label>{base.i18n.t('expired_date')}</label>
                <DatePicker locale={base.locale_string} value={expired_date} onChange={date => set_expired_date(date)} minDate={new Date(start_date)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mb-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
