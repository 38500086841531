import { useEffect, useState } from 'react';
import queryString from "query-string";

import Base from '../../../utils/base'

export default function CourierAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [name, set_name] = useState('')
  const [code, set_code] = useState('')
  const [file_name, set_file_name] = useState('')
  const [is_enabled, set_is_enabled] = useState(false)
  const [base64, set_base64] = useState(null)
  const [query, set_query] = useState({})

  useEffect(() => {
    base.limit_data_length(name, set_name)
  }, [name,])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    if(query1.id != null)
      get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/courier?id=' + queryParams.id).then(response => {
      if(response.status === 'success'){
        if(response != null && response.data.file_name != null)
          set_file_name(base.host + '/image/courier?file_name=' + response.data.file_name)
        set_data(response.data)
        set_name(response.data.name)
        set_code(response.data.code)
        set_is_enabled(response.data.is_enabled)
      }
    })
  }

  function on_upload_image(e){
    var image = URL.createObjectURL(e.target.files[0])
    base.getBase64(e.target.files[0], result => {
      set_base64(result)
    })

    set_file_name(image)
  }

  function submit() {
    if(name === '')
      base.notify_user(base.i18n.t('name_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        name: name,
        code: code,
        is_enabled: is_enabled,
      }
      if(base64 != null)
        temp.image = {
          image: base64
        }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/courier', queryParams.id == null ? 'post' : 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/courier"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_courier') : base.i18n.t('add_courier')}</h3>
      <div className="row">
        <div className="col-12 col-lg-4 mb-3">
          <div className="card">
            <div className="card-body">
              <input type="file" className="d-none" id="inputFile" onChange={e => on_upload_image(e)}/>
              <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
              <div className="mt-3">
                {
                  file_name != null && file_name != '' ?
                  <img src={file_name} width="100%"/>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 mb-3">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('status_enabled')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="0" onChange={() => set_is_enabled(0)} id="flexRadioDefaultYes" checked={is_enabled === 0}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultYes">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="1" onChange={() => set_is_enabled(1)} id="flexRadioDefaultNo" checked={is_enabled === 1}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultNo">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('code')}</label>
                <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_code(e.target.value)} value={code}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('name')}</label>
                <input type="text" className="form-control" disabled={query.id != null} onChange={e => set_name(e.target.value)} value={name}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
