import axios from 'axios'
import moment from 'moment'
import {NotificationManager} from 'react-notifications'

import I18n from "./i18n"

export default class Base{
  host = 'https://api.lumoshliving.com'
  is_loading = false
  search_wait_time = 1000
  locale_string = 'id-ID'
  i18n = I18n
  moment_locale = 'Asia/Jakarta'

  constructor(){
    // if(window.localStorage.getItem('token') == null){
    //   window.localStorage.setItem('token', "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvbHVtb3MtYXBpLnF1YW50dW10cmkuY29tXC9hdXRoXC9sb2dpbiIsImlhdCI6MTYxOTc1NzYxMiwiZXhwIjoxNjUwODYxNjEyLCJuYmYiOjE2MTk3NTc2MTIsImp0aSI6Im5iVWVGYk5UOGN6cEljZmIiLCJzdWIiOiJVU0VSXzAwMDEiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3In0.b7NJbNv3APFUehNOb1xZzKdw2xRcAto8cs_4hlAIFlI")
    //   window.localStorage.setItem('token_expired', moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'))
    // }

    axios.defaults.headers.common['Content-Type'] = 'application/json'
    axios.defaults.timeout = 600000
    if(window.location.pathname !== '/auth/login')
      window.localStorage.setItem('destination_path', window.location.pathname)
    this.redirect_to_login()
  }

  async check_token_expired(){
    var token_expired = window.localStorage.getItem('token_expired')
    if(window.location.pathname !== '/auth/login'){
      if(token_expired != null){
        token_expired = moment(token_expired)

        if(moment() <= token_expired){
          window.localStorage.setItem('token_expired', moment().add(3, 'hours').format('YYYY-MM-DD HH:mm:ss'))
        }
        else{
          return false
        }
      }
      else{
        return false
      }
    }
    return true
  }

  async remove_token(){
    var token = window.localStorage.getItem('token')

    var response = await axios.delete(this.host + '/user/token?token=' + token)
    if(response.data != null && response.data.status === 'success'){
      // window.localStorage.removeItem('token')
      // window.localStorage.removeItem('token_expired')
    }
  }

  async request(url, method = "get", data = {}, contentType = 'multipart/form-data', onUploadProgress = response => {}, use_image = false){
    axios.defaults.headers.common['Authorization'] = await window.localStorage.getItem('token')

    try{
      if(!use_image)
        window.$('#pleaseWait').modal('show')
      else
        window.$('#pleaseWaitWithProgress').modal('show')

			var arr_body = null
			if(contentType == 'multipart/form-data'){
      	arr_body = new FormData()
      	for(let x in data){
        	arr_body.append(x, data[x])
      	}
			}
			else if(contentType == 'application/json')
				arr_body = data

      var response
      if(method === 'get')
        response = await axios.get(url)
      else if(method === 'post')
        response = await axios.post(url, arr_body, {
          headers: {
            "Content-Type": contentType,
          },
          onUploadProgress
        })
      else if(method === 'put')
        response = await axios.put(url, arr_body, {
          headers: {
            "Content-Type": contentType,
          },
          onUploadProgress
        })
      else if(method === 'delete')
        response = await axios.delete(url)

      setTimeout(() => {
        window.$('#pleaseWait').modal('hide')
        window.$('#pleaseWaitWithProgress').modal('hide')
      }, 500)

      if(response.data.status === 'success')
        return response.data

      this.notify_user(response.data.message, 'error')
      if(response.data.error_data === 'token_invalid' || response.data.error_data === 'token_revoked' || response.data.error_data === 'token_not_found'){
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('token_expired')
        window.location.href = "/auth/login"
      }
    } catch (e) {
      setTimeout(() => {
        window.$('#pleaseWait').modal('hide')
        window.$('#pleaseWaitWithProgress').modal('hide')
      }, 500)
      this.notify_user(e.message, 'error')
    }
  }

  async redirect_to_login(){
    var token = await window.localStorage.getItem('token')
    var response = await this.check_token_expired()

    if((token === '' || token == null || !response) && window.location.pathname !== '/auth/login'){
      this.logout_action()
    }
  }

  logout_action(){
    this.remove_token()
    window.localStorage.removeItem('token')
    window.localStorage.removeItem('token_expired')
    window.localStorage.removeItem('order_tab')
    window.localStorage.removeItem('per_page')
    window.localStorage.removeItem('current_page')
    window.localStorage.removeItem('arr_per_page')
    window.localStorage.removeItem('arr_current_page')
    window.location.href = '/auth/login'
  }

  getBase64(file, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      callback(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }
  
  str_to_double(data, default_value = '0'){
    var value
    if(data != '')
      value = parseFloat(data.replace(/\./g,'').replace(/,/g,'.'))
    else
      value = default_value
    return value
  }
  
  to_currency_format_num(data, max_number = 100000000, max_comma_length = 2){
    var value = data
    
    if(value[value.length - 1] !== ","){
      var is_include_comma = false
      var is_convert_double = true
      var index_comma = 0
      for(let x = 0; x < value.length; x++){
        if(value[x] === ","){
          is_include_comma = true
          index_comma = x
        }
        else if(is_include_comma && x == value.length - 1 && value[x] === "0")
          is_convert_double = false
      }
  
      if(is_include_comma){
        is_convert_double = value.length - index_comma > max_comma_length && value[value.length - 2] !== "0"
        value = value.substring(0, index_comma + 1 + max_comma_length)
      }
      
      
      if(is_convert_double){
        value = this.str_to_double(value)
        if(isNaN(value))
          value = 0
        if(value > max_number)
          value = max_number
      }
    }
  
    return value.toLocaleString(this.locale_string)
  }

  to_currency_format(data, set_data = null){
//     if(isNaN(data)){
//       if(set_data != null){
//         set_data('0')
//         return
//       }
//       else
//         return '0'
//     }
// 
//     if(data[data.length - 1] === ','){
//       if(set_data != null){
//         set_data(data)
//         return
//       }
//       else
//         return data
//     }
//     else if(data[data.length - 1] === '-'){
//       if(set_data != null){
//         set_data(data.substring(0, data.length - 2))
//         return
//       }
//       else
//         return data.substring(0, data.length - 2)
//     }
// 
//     var value
//     if(data != '')
//       value = parseFloat(data.replace(/\./g,'').replace(/,/g, '.'))
//     else
//       value = 0

    if(set_data != null)
      set_data(this.to_currency_format_num(data))
    else
      return this.to_currency_format_num(data)
  }

  notify_user(message, type = 'info'){
    if(type == 'error')
      NotificationManager.error(message)
    else
      NotificationManager.info(message)
  }

  add_array(arr, set_state, data = {}){
    var temp = [...arr]
    temp.push(data)
    set_state(temp)
  }

  update_array(arr, set_state, data = {}, index = 0){
    var temp = [...arr]
    temp[index] = data
    set_state(temp)
  }

  remove_array(arr, set_state, index = 0){
    var temp = [...arr]
    temp.splice(index, 1)
    set_state(temp)
  }

  update_object(data, set_state, variable = null, key = ''){
    var temp = JSON.parse(JSON.stringify(data))
    temp[key] = variable
    set_state(temp)
  }

  async get_file(image){
    const response = await fetch(image);
    const blob = await response.blob();
    const file = new File([blob], 'image.jpg', {type: blob.type});
    return file
  }

  get_array_index_sub_variant(arr_variant){
    var flag = true;
    var arr = [];
    var arr_count = [];
    var arr_count1 = [];
    var arr_final_index = [];
    var total = 1;

    arr_variant.forEach((variant, index) => {
      arr_count.push(variant.arr_sub_variant.length)
      total *= variant.arr_sub_variant.length
      arr_count1.push(0)
    })

    var counter = 0;
    while(counter < total){
      var arr_temp = []
      var temp = 0
      if(counter > 0){
        var arr_temp1 = JSON.parse(JSON.stringify(arr_count1))
        for(let key in arr_temp1){
          arr_temp1[key]++
          if(arr_temp1[key] === arr_count[key]){
            arr_temp1[key] = 0
            continue
          }
          break
        }

        arr_count1 = arr_temp1
      }
      arr_final_index.push(arr_count1)
      counter++
    }

    return arr_final_index
  }

  reset_localstorage(){
    window.localStorage.removeItem('variant')
    window.localStorage.removeItem('index')
    window.localStorage.removeItem('arr_variant')
    window.localStorage.removeItem('data_product')
  }

  limit_data_length(data, set_data = null, length = 100){
    if(data != null){
      if(set_data != null)
        set_data(data.substring(0, length))
      return data.substring(0, length)
    }

    if(set_data != null)
      set_data('')
    return ''
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
