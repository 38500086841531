import { useEffect, useState } from 'react';
import queryString from "query-string";
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';

import Base from '../../../utils/base'

export default function BlogAction(props) {
  var base = new Base()
  const [data, set_data] = useState({})
  const [is_publish, set_is_publish] = useState(0)
  const [title, set_title] = useState('')
  const [content, set_content] = useState('')
  const [file_name, set_file_name] = useState('')
  const [base64, set_base64] = useState(null)
  const [query, set_query] = useState({})

  useEffect(() => {
    base.limit_data_length(title, set_title)
  }, [title,])

  useEffect(() => {
    var query1 = queryString.parse(props.location.search)
    set_query(query1)

    if(query1.id != null)
      get_data()
  }, [])

  function get_data() {
    var queryParams = queryString.parse(props.location.search)
    base.request(base.host + '/blog?id=' + queryParams.id).then(response => {
      if(response != null && response.status === 'success'){
        if(response.data.file_name != null)
          set_file_name(base.host + '/image/blog?file_name=' + response.data.file_name)
        set_data(response.data)
        set_title(response.data.title)
        set_content(response.data.content)
        set_is_publish(response.data.is_publish)

        window.$('.summernote').summernote({
          placeholder: 'Hello Bootstrap 4',
          tabsize: 2,
          height: 100
        })
      }
    })
  }

  function on_upload_image(e){
    var image = URL.createObjectURL(e.target.files[0])
    base.getBase64(e.target.files[0], result => {
      set_base64(result)
    })

    set_file_name(image)
  }

  function submit() {
    if(title === '')
      base.notify_user(base.i18n.t('title_empty'))
    else if(content === '')
      base.notify_user(base.i18n.t('content_empty'))
    else{
      var queryParams = queryString.parse(props.location.search)
      var temp = {
        title: title,
        content: content,
        is_publish: is_publish,
      }
      if(base64 != null)
        temp.image = {
          image: base64
        }
      if(data.id != null)
        temp.id = data.id

      base.request(base.host + '/blog', queryParams.id == null ? 'post' : 'put', temp).then(response => {
        if(response != null && response.status === 'success'){
          window.location.href = "/blog"
        }
      })
    }
  }

  return (
    <div>
      <h3>{query.id != null ? base.i18n.t('edit_blog') : base.i18n.t('add_blog')}</h3>
      <div className="row">
        <div className="col-12 col-lg-4 mb-3">
          <div className="card">
            <div className="card-body">
              <input type="file" className="d-none" id="inputFile" onChange={e => on_upload_image(e)}/>
              <button className="btn btn-primary" onClick={e => window.$('#inputFile').trigger('click')}>{base.i18n.t('upload_file')}</button>
              <div className="mt-3">
                {
                  file_name != null && file_name != '' ?
                  <img src={file_name} width="100%"/>
                  :
                  <></>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className="card">
            <div className="card-body">
              <div className="form-group">
                <label>{base.i18n.t('status_publish')}</label>
                <div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="0" onChange={() => set_is_publish(0)} id="flexRadioDefaultYes" checked={is_publish === 0}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultYes">{base.i18n.t('no')}</label>
                  </div>
                  <div className="form-check-inline">
                    <input className="form-check-input" type="radio" name="status" value="1" onChange={() => set_is_publish(1)} id="flexRadioDefaultNo" checked={is_publish === 1}/>
                    <label className="form-check-label" htmlFor="flexRadioDefaultNo">{base.i18n.t('yes')}</label>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('title')}</label>
                <input type="text" className="form-control" onChange={e => set_title(e.target.value)} value={title}/>
              </div>
              <div className="form-group">
                <label>{base.i18n.t('content')}</label>
                <ReactSummernote
                  value={content}
                  onChange={content => set_content(content)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 mt-3">
          <button className="btn btn-primary" onClick={() => submit()}>{base.i18n.t('submit')}</button>
        </div>
      </div>
    </div>
  );
}
